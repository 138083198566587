import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateAppCode } from 'store/slices/currentUser/currentUserSlice';
import { Box, Paper, useTheme } from '@mui/material';
import { Button, Typo } from 'components/primitives';
import useStyles, { Img } from './AppCard.styles';
import Logo from 'assets/img.png';
import { ProgramCodes, SiteStatusCodes } from 'common/enum';
import { findKeyIndAttribute } from 'common/utils';
import { Attributes } from 'interfaces/attribute.interface';
import { IUserPortal } from 'interfaces/userinfo.interface';
import 'theme/styles/muiPaperOverwrite.css';

interface IAppCard {
  title: string;
  subtitle: string;
  appCode: string;
  activeGroupId: number;
  currentUser: Partial<IUserPortal> | null;
  withMobile?: boolean;
  isMobile?: boolean;
  cb?: (appName: string, withMobile?: boolean) => void;
  handleOpenModal?: () => void;
}

const AppCard: React.FC<IAppCard> = ({ title, subtitle, appCode, currentUser, cb, ...props }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [allowAccess, setAllowAccess] = useState<boolean>(false);
  const [btnTitle, setBtnTitle] = useState<string>('REQUEST ACCESS');
  const [disableRequest, setDisableRequest] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser) {
      if (appCode === ProgramCodes.PEOC) {
        setAllowAccess(true);
      } else {
        if (currentUser?.attributes) {
          if (Array.isArray(currentUser.attributes)) {
            const tempAttributes = currentUser.attributes;
            for (const attribute of tempAttributes) {
              if (typeof attribute === 'object') {
                const checkCode = (attribute as Attributes)[appCode];
                const isCodeExist = checkCode !== undefined;
                if (isCodeExist) {
                  const infoObj = checkCode;
                  if (Array.isArray(infoObj)) {
                    if (infoObj.length > 1) {
                      const indexOfActiveGroup = findKeyIndAttribute(
                        infoObj,
                        String(props.activeGroupId)
                      );
                      if (indexOfActiveGroup > -1) {
                        const accessApp = infoObj[indexOfActiveGroup] as unknown as
                          | {
                              S: SiteStatusCodes;
                            }
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          | any;
                        if (typeof accessApp === 'object') {
                          if (
                            Object.prototype.hasOwnProperty.call(
                              accessApp,
                              String(props.activeGroupId)
                            )
                          ) {
                            const stringActiveGroupID = String(props.activeGroupId);
                            const infoAccessApp = accessApp[stringActiveGroupID];
                            if (Array.isArray(infoAccessApp)) {
                              const idxOfStatus = findKeyIndAttribute(infoAccessApp, 'S');
                              if (idxOfStatus > -1) {
                                const siteStatusInfo = infoAccessApp[idxOfStatus] as unknown as {
                                  S: SiteStatusCodes;
                                };
                                if (siteStatusInfo && siteStatusInfo.S === SiteStatusCodes.ACTIVE)
                                  setAllowAccess(true);
                                if (
                                  siteStatusInfo &&
                                  siteStatusInfo.S === SiteStatusCodes.REQUEST
                                ) {
                                  setBtnTitle('PENDING APPROVAL');
                                  setDisableRequest(true);
                                }
                              }
                            }
                          }
                        }
                        if (accessApp && accessApp.S === SiteStatusCodes.ACTIVE)
                          setAllowAccess(true);
                        if (accessApp && accessApp.S === SiteStatusCodes.REQUEST) {
                          setBtnTitle('PENDING APPROVAL');
                          setDisableRequest(true);
                        }
                      }
                    } else {
                      const accessApp = infoObj[0] as unknown as { S: SiteStatusCodes };
                      if (accessApp && accessApp.S === SiteStatusCodes.ACTIVE) setAllowAccess(true);
                      if (accessApp && accessApp.S === SiteStatusCodes.REQUEST) {
                        setBtnTitle('PENDING APPROVAL');
                        setDisableRequest(true);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleClickEnter = (isMobile?: boolean) => {
    if (cb) cb(appCode, isMobile);
  };

  const handleRequest = () => {
    dispatch(updateAppCode(appCode));
    if (props.handleOpenModal) props.handleOpenModal();
  };

  return (
    <Paper id='muiPaper' elevation={0} sx={classes.container}>
      <Box sx={classes.imgContainer}>
        <Img src={Logo} alt='logo' style={{ objectFit: 'contain' }} />
      </Box>
      <Box sx={classes.titleContainer}>
        <Typo fontWeight={600} fontSize={16} sx={{ textAlign: 'center' }}>
          {title}
        </Typo>
        <Typo color={theme.palette.grey[700]} sx={classes.subtitle}>
          {subtitle}
        </Typo>
      </Box>
      {allowAccess ? (
        props.withMobile ? (
          <Box sx={classes.launchOption.container}>
            {!props.isMobile && <Typo sx={classes.launchOption.title}>Launch options</Typo>}
            <Box sx={classes.launchOption.row}>
              {!props.isMobile && (
                <Button fullWidth onClick={() => handleClickEnter()}>
                  DESKTOP
                </Button>
              )}
              <Button
                fullWidth
                alternate
                sx={{ backgroundColor: '#E2EAFF' }}
                onClick={() => handleClickEnter(props.withMobile)}>
                MOBILE
              </Button>
            </Box>
          </Box>
        ) : (
          <Button fullWidth onClick={() => handleClickEnter()}>
            ENTER
          </Button>
        )
      ) : (
        <Button alternate fullWidth onClick={handleRequest} disabled={disableRequest}>
          {btnTitle}
        </Button>
      )}
    </Paper>
  );
};

export default AppCard;
