import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';

const Img = styled(`img`)`
  width: 48px;
  height: 100%;
  object-fit: contain;
`;

export { Img };

const useStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    height: '230px',
    minWidth: '315px',
    maxWidth: '348px',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0',
    borderRadius: '2px',
    background: theme.palette.common.white,
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      width: '100%',
    },
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    flex: '1 0 0',
    alignSelf: 'stretch',
  },
  subtitle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    alignSelf: 'stretch',
    overflow: 'hidden',
    color: 'rgba(0, 0, 0, 0.54)',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
  },
  launchOption: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '2px',
    },
    row: {
      display: 'flex',
      width: '100%',
      gap: '8px',
    },
    title: {
      fontSize: '0.80rem',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
});

export default useStyles;
