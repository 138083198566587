import { AssignGroupFormStep, SiteStatusCodes } from 'common/enum';
import { Attributes } from 'interfaces/attribute.interface';

export interface StackableStep {
  active?: boolean;
  complete?: boolean;
  step: number | string;
  title?: string;
  name?: string;
}

export const defaultAttribute: Attributes[] = [
  {
    PEOC: [{ S: SiteStatusCodes.ACTIVE }],
  },
  {
    LEMPG: [{ S: SiteStatusCodes.DENY }],
  },
  {
    MITG: [{ S: SiteStatusCodes.DENY }],
  },
  {
    SERCLEPC: [{ S: SiteStatusCodes.DENY }],
  },
  {
    PTRACK: [{ S: SiteStatusCodes.DENY }],
  },
  {
    PTRAIN: [{ S: SiteStatusCodes.ACTIVE }],
  },
  {
    SUPPTL: [{ S: SiteStatusCodes.DENY }],
  },
  {
    EGRESS: [{ S: SiteStatusCodes.DENY }],
  },
  {
    DMA: [{ S: SiteStatusCodes.DENY }],
  },
  {
    DMAM: [{ S: SiteStatusCodes.DENY }],
  },
  {
    CDOWN: [{ S: SiteStatusCodes.DENY }],
  },
  {
    REP: [{ S: SiteStatusCodes.DENY }],
  },
  {
    VEOC: [{ S: SiteStatusCodes.DENY }],
  },
  {
    HURH: [{ S: SiteStatusCodes.DENY }],
  },
  {
    GIS: [{ S: SiteStatusCodes.DENY }],
  },
  {
    DECON: [{ S: SiteStatusCodes.DENY }],
  },
  {
    DB30: [{ S: SiteStatusCodes.DENY }],
  },
  {
    MP30: [{ S: SiteStatusCodes.DENY }],
  },
];

export const LEMPGSelectOptions = [
  {
    title: 'CERT',
    value: 'CE',
  },
  {
    title: 'Chief Of Staff',
    value: 'CA',
  },
  {
    title: 'FEMA',
    value: 'FE',
  },
  {
    title: 'Region 1',
    value: 'R1',
  },
  {
    title: 'Region 2',
    value: 'R2',
  },
  {
    title: 'Region 3',
    value: 'R3',
  },
  {
    title: 'Region 4',
    value: 'R4',
  },
  {
    title: 'Region 5',
    value: 'R5',
  },
  {
    title: 'Region 6',
    value: 'R6',
  },
  {
    title: 'SLED',
    value: 'SL',
  },
  {
    title: 'SME',
    value: 'SM',
  },
  {
    title: 'State',
    value: 'ST',
  },
];

export const MitigationSelectOptions = [
  {
    title: 'Full Access',
    value: 'F',
  },
  {
    title: 'Limited Access',
    value: 'L',
  },
];

export const REPSelectOptions = [
  {
    title: 'Full Access',
    value: 'F',
  },
  {
    title: 'Limited Access',
    value: 'L',
  },
  {
    title: 'SERT',
    value: 'C',
  },
  {
    title: 'REM',
    value: 'R',
  },
];

export const DMASelectOptions = [
  {
    title: 'Administrator',
    value: 'A',
  },
  {
    title: 'Users',
    value: 'U',
  },
  {
    title: 'Finance',
    value: 'F',
  },
  {
    title: 'LIDA Data Entry',
    value: 'L',
  },
];

export const pageSteps: StackableStep[] = [
  {
    step: AssignGroupFormStep.GROUP,
    title: 'Group',
    active: true,
  },
  {
    step: AssignGroupFormStep.POSITION,
    title: 'Position',
    active: false,
  },
];

export const SCEMDCountyIDs = [
  1, 3, 4, 5, 7, 12, 13, 14, 17, 21, 22, 24, 30, 31, 32, 36, 37, 39, 40, 45, 47,
];
