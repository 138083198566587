import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useMedia, { MOBILE_SIZE } from 'hooks/useMediaQuery';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectLEMPGAuth,
  selectSUPPAuth,
  selectUserAccount,
} from 'store/slices/currentUser/currentUser.selector';
import { selectAccessToken, selectJWTToken } from 'store/slices/auth/auth.selector';
import { changeMenu } from 'store/slices/menu/menuSlice';
import { updateUserAttribute } from 'store/slices/userManage/userManageThunk';
import { fetchCurrentUserInfo } from 'store/slices/currentUser/currentUserThunk';
import {
  updateCurrentUser,
  updateLEMPGAuth,
  updateSUPPAuth,
} from 'store/slices/currentUser/currentUserSlice';
import { updateActiveGroupId } from 'store/slices/cognito/cognitoSlice';
import DashboardView from './Dashboard.view';
import { DashboardProps } from './Dashboard.props';
import {
  ProgramCodes,
  ProgramSites,
  ProgramTitles,
  SiteStatusCodes,
  ToastStatus,
} from 'common/enum';
import { Attributes, PayloadAttribute } from 'interfaces/attribute.interface';
import { ToastMessage } from 'components/primitives';
import {
  AppCodeIdToken,
  DefineUserAttributesData,
  findKeyIndAttribute,
  SetNewAttributesData,
  VerifyAttributesForGroupID,
} from 'common/utils';

const Dashboard = () => {
  const { isMobileDevice, windowDimensions } = useMedia();
  const isMobile = isMobileDevice || (windowDimensions?.width ?? 1000) < MOBILE_SIZE;
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUserAccount);
  const activeGroupId = (currentUser && currentUser?.pvActiveGroupID) || 0; // useAppSelector(selectActiveGroupId);
  const accessToken = useAppSelector(selectAccessToken);
  const jwtToken = useAppSelector(selectJWTToken);
  const isLEMPGAuth = useAppSelector(selectLEMPGAuth);
  const isSUPPAuth = useAppSelector(selectSUPPAuth);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [detailsValue, setDetailsValue] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [toggleAllSites, setToggleAllSites] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [selectedApp, setSelectedApp] = useState<string | null>(null);
  const [groupName, setGroupName] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentUser && currentUser?.currentGroups && currentUser?.currentGroups.length) {
      const filterGroup = currentUser.currentGroups.find((g) => g.i === activeGroupId);
      if (filterGroup && filterGroup.n) setGroupName(filterGroup.n);
    }
  }, [activeGroupId, currentUser]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      dispatch(fetchCurrentUserInfo(currentUser.id))
        .unwrap()
        .then((userRecord) => {
          const copyUserRecord = { ...userRecord };
          copyUserRecord.attributes = DefineUserAttributesData(userRecord?.attributes || []);
          if (copyUserRecord && copyUserRecord.id) {
            if (
              copyUserRecord &&
              copyUserRecord?.currentGroups &&
              !copyUserRecord.pvActiveGroupID
            ) {
              // *: Check if user has no active group
              const currentGroups = copyUserRecord.currentGroups;
              const activeGroup = currentGroups[0].i;
              dispatch(updateActiveGroupId({ id: activeGroup }));
              const updateUser = { ...copyUserRecord, pvActiveGroupID: activeGroup };
              dispatch(updateCurrentUser(updateUser));
            }
            const userAttributes = copyUserRecord.attributes || [];
            let newAttribute: Attributes[] = userAttributes;
            let isVEOCChange = false;
            let isPTRAINChange = false;
            // *: Check if VEOC access is Denied
            const indexOfV = findKeyIndAttribute(userAttributes, ProgramCodes.VEOC);
            if (indexOfV > -1) {
              const isGroupExist = VerifyAttributesForGroupID(
                userAttributes,
                activeGroupId,
                ProgramCodes.VEOC
              );
              const arrObj = userAttributes[indexOfV][ProgramCodes.VEOC];
              const idxOfS = findKeyIndAttribute(arrObj, 'S');
              if (idxOfS > -1 && arrObj[idxOfS]['S'] === SiteStatusCodes.DENY) {
                isVEOCChange = true;
                newAttribute = SetNewAttributesData(
                  userAttributes,
                  indexOfV,
                  ProgramCodes.VEOC,
                  SiteStatusCodes.ACTIVE,
                  activeGroupId,
                  isGroupExist,
                  'U',
                  false,
                  true
                );
              }
            }
            // *: Check if Palmetto Training access is Denied
            const indexOfA = findKeyIndAttribute(newAttribute, ProgramCodes.PTRAIN);
            if (indexOfA > -1) {
              const isGroupExist = VerifyAttributesForGroupID(
                newAttribute,
                activeGroupId,
                ProgramCodes.PTRAIN
              );
              const arrObj = newAttribute[indexOfA][ProgramCodes.PTRAIN];
              const idxOfS = findKeyIndAttribute(arrObj, 'S');
              if (idxOfS > -1 && arrObj[idxOfS]['S'] === SiteStatusCodes.DENY) {
                isPTRAINChange = true;
                const copyOfAttr = newAttribute;
                newAttribute = SetNewAttributesData(
                  copyOfAttr,
                  indexOfA,
                  ProgramCodes.PTRAIN,
                  SiteStatusCodes.ACTIVE,
                  activeGroupId,
                  isGroupExist
                );
              }
            }
            const updateUser = { ...copyUserRecord, attributes: newAttribute };
            dispatch(updateCurrentUser(updateUser));
            const fullName =
              (copyUserRecord &&
                copyUserRecord?.pvPersonGivenName &&
                copyUserRecord?.pvPersonSurName &&
                `${copyUserRecord?.pvPersonGivenName} ${copyUserRecord?.pvPersonSurName}`) ||
              '';
            const userEmail = copyUserRecord.email ?? '';

            if (isVEOCChange || isPTRAINChange) {
              const appCode = isVEOCChange ? ProgramCodes.VEOC : ProgramCodes.PTRAIN;
              const siteName = ProgramTitles[appCode];
              const payload: PayloadAttribute = {
                id: copyUserRecord.id,
                groupId: activeGroupId,
                attributes: { attributes: newAttribute },
                isRequest: false,
                isDeny: false,
                isGrant: false,
                info: {
                  site: siteName,
                  email: userEmail,
                  name: fullName,
                },
              };
              dispatch(updateUserAttribute(payload));
            }
          }
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenu = () => {
    dispatch(changeMenu({ BtnAction: true }));
  };

  const openPage = async (appCode: string, withMobile?: boolean) => {
    setSelectedApp(appCode);
    const indexOf = Object.keys(ProgramSites).findIndex((val) => val === appCode);
    const programValues = Object.values(ProgramSites);
    const siteURL = programValues[indexOf];

    if (appCode === ProgramCodes.LEMPG && !isLEMPGAuth) {
      setOtpModal(true);
    } else if (appCode === ProgramCodes.SUPPTL && !isSUPPAuth) {
      setOtpModal(true);
    } else {
      if (indexOf > -1 && accessToken && currentUser) {
        let url: string;
        if (AppCodeIdToken(appCode)) {
          url = `${siteURL}?id_token=${jwtToken}`;
        } else if (appCode === ProgramCodes.PTRAIN || appCode === ProgramCodes.PEOC) {
          if (withMobile) {
            url = `${siteURL}/app?access_token=${accessToken}&userId=${currentUser.id}`;
          } else {
            url = `${isMobile ? `${siteURL}/app` : siteURL}?access_token=${accessToken}&userId=${
              currentUser.id
            }`;
          }
        } else if (appCode === ProgramCodes.HURH) {
          url = siteURL;
        } else {
          url = `${siteURL}?access_token=${accessToken}&user_id=${currentUser.id}`;
        }
        const newWindow = window.open(url);
        if (newWindow) {
          newWindow.focus();
        }
      }
    }
  };

  const onChangeDetails = (value: string) => setDetailsValue(value);

  const onSubmitRequest = async (confirm: boolean, id: number, appCode: ProgramCodes | string) => {
    if (confirm) {
      if (id && appCode && currentUser) {
        const currentAttributes = DefineUserAttributesData(currentUser.attributes || []);
        if (currentAttributes && activeGroupId) {
          const indexOfA = findKeyIndAttribute(currentAttributes, appCode);
          const newAttribute = SetNewAttributesData(
            currentAttributes,
            indexOfA,
            appCode,
            SiteStatusCodes.REQUEST,
            activeGroupId,
            false
          );

          // *: Get App Title
          let appTitle: string;
          if (appCode in ProgramTitles) {
            appTitle = ProgramTitles[appCode as ProgramCodes];
          } else {
            appTitle = Object.values(ProgramTitles)[indexOfA];
          }
          const payload: PayloadAttribute = {
            id,
            groupId: activeGroupId,
            attributes: { attributes: newAttribute },
            isRequest: true,
            isDeny: false,
            isGrant: false,
            info: {
              message: detailsValue.replace(/\r\n|\r|\n/g, '<br />'),
              name: `${currentUser.pvPersonGivenName} ${currentUser.pvPersonSurName}`,
              cellPhone: currentUser.pvMobilePhone || '',
              workPhone: currentUser.pvOfficePhone || '',
              email: currentUser.email || '',
              site: appTitle || '',
            },
          };
          setIsProcessing(true);
          await dispatch(updateUserAttribute(payload))
            .unwrap()
            .then(async () => {
              await dispatch(fetchCurrentUserInfo(payload.id))
                .unwrap()
                .then((userRecord) => {
                  if (userRecord && userRecord.id) {
                    dispatch(updateCurrentUser(userRecord));
                  }
                  toast.info(
                    <ToastMessage
                      status={ToastStatus.SUCCESS}
                      message='Your request access has been sent!'
                    />
                  );
                  setIsProcessing(false);
                })
                .catch(() => {
                  toast.info(
                    <ToastMessage status={ToastStatus.ERROR} message='Request sent failed!' />
                  );
                  setIsProcessing(false);
                });
            })
            .catch(() => {
              toast.info(
                <ToastMessage status={ToastStatus.ERROR} message='Request sent failed!' />
              );
              setIsProcessing(false);
            });
        }
        setOpenModal(false);
        setDetailsValue('');
      }
    } else {
      setOpenModal(false);
      setDetailsValue('');
    }
  };

  const handleToggle = (_event: React.MouseEvent<HTMLElement>, newValue: boolean) => {
    setToggleAllSites(newValue);
  };

  const handleOTPModal = (confirmation: boolean) => {
    if (confirmation && selectedApp) {
      setOtpModal(false);
      if (selectedApp === ProgramCodes.LEMPG) dispatch(updateLEMPGAuth(true));
      if (selectedApp === ProgramCodes.SUPPTL) dispatch(updateSUPPAuth(true));
      const indexOf = Object.keys(ProgramSites).findIndex((val) => val === selectedApp);
      const programValues = Object.values(ProgramSites);
      const siteURL = programValues[indexOf];
      if (indexOf > -1 && accessToken && currentUser) {
        const url = `${siteURL}?id_token=${jwtToken}`;
        const newWindow = window.open(url);
        if (newWindow) newWindow.focus();
      }
    } else {
      setOtpModal(false);
    }
  };

  const combineProps: DashboardProps = {
    isMobile,
    isLoading,
    toggleAllSites,
    openModal,
    detailsValue,
    isProcessing,
    activeGroupId,
    currentUser,
    jwtToken,
    otpModal,
    groupName,
    openPage,
    handleMenu,
    handleOTPModal,
    onChangeDetails,
    onSubmitRequest,
    handleToggle,
    handleOpenModal: () => setOpenModal(!openModal),
  };

  return <DashboardView {...combineProps} />;
};

export default Dashboard;
