import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCognitoUser } from 'store/slices/cognito/cognito.selector';
import { selectUserAccount } from 'store/slices/currentUser/currentUser.selector';
import { fetchGroups } from 'store/slices/userManage/userManageThunk';

const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const cognitoUser = useAppSelector(selectCognitoUser);
  const userAccount = useAppSelector(selectUserAccount);
  const accessToken = searchParams.get('access_token');
  const userId = searchParams.get('userId');
  const resetPassword = searchParams.get('resetPassword');
  const token = searchParams.get('token');
  const uid = searchParams.get('uid');

  useEffect(() => {
    dispatch(fetchGroups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlPath = location.pathname;
    // ?: Clear all stored Cognito tokens
    localStorage.clear();
    if (cognitoUser && userAccount?.id) {
      console.log('Triggered redirect to dashboard');
      if (urlPath === '/') return navigate('/dashboard');
      if (urlPath === '/login') return navigate('/dashboard');
      return navigate(urlPath);
    }
    if (urlPath === '/forgotpassword') return navigate(urlPath);
    if (urlPath === '/forgotusername') return navigate(urlPath);
    if (urlPath === '/requestaccess') return navigate(urlPath);
    if (urlPath === '/resetpassword' && accessToken && userId) {
      return navigate(`/resetpassword?access_token=${accessToken}&userId=${userId}`);
    }
    if (urlPath === '/verifyAccount' && token && uid) {
      return navigate(`/verifyAccount?uid=${uid}&token=${token}`);
    }
    if (urlPath === '/login' && resetPassword) {
      return navigate('/login?resetPassword=true');
    }
    if (urlPath === '/pdf/portal-user-guide.pdf') {
      return navigate('/pdf/portal-user-guide.pdf');
    }
    navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser, userAccount, accessToken, userId, token, uid]);

  return <Outlet />;
};

export default AuthPage;
