import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import userManageSelect from 'store/slices/userManage/userManage.selector';
import {
  selectAccessPermission,
  selectUserAccount,
} from 'store/slices/currentUser/currentUser.selector';
import { selectActiveGroupId } from 'store/slices/cognito/cognito.selector';
import { updateUser } from 'store/slices/currentUser/currentUserThunk';
import {
  deleteUserEntry,
  deleteUserToGroup,
  fetchSelectedUser,
} from 'store/slices/userManage/userManageThunk';
import { updateUserPermission } from 'store/slices/userManage/userManageSlice';
import useUserForm, { IUserFormData } from './hook/useUserForm';
import UserEditView from './UserEdit.view';
import { UserEditProps } from './UserEdit.props';
import { UserStatus } from 'common/enum';
import { generateDataPermissions } from 'common/utils';
import { IUserUpdatePayload } from 'interfaces/userinfo.interface';
import { IPermissionList } from 'interfaces/persmissionlist.interface';
import { IRevokeGroupPayload } from '../../interfaces/groups.interface';
import userManageSelector from '../../store/slices/userManage/userManage.selector';

const UserEdit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRecord = useAppSelector(userManageSelect.selectedUser);
  const groupLists = useAppSelector(userManageSelector.selectGroupLists);
  const isFetching = useAppSelector(userManageSelect.isLoading);
  const userPermission = useAppSelector(selectAccessPermission);
  const userActiveGroupID = useAppSelector(selectActiveGroupId);
  const currentUserAccount = useAppSelector(selectUserAccount);
  const loggedInUserGroupInfo = (currentUserAccount && currentUserAccount?.currentGroups) || [];
  const form = useUserForm();
  const [userFullName, setUserFullName] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [processingDelete, setProcessingDelete] = useState(false);
  const [isAccountGroupAdmin, setIsAccountGroupAdmin] = useState(false);
  const [isAccountSuperAdmin, setIsAccountSuperAdmin] = useState(false);
  const currentUserGroupInfo = userRecord?.currentGroups || [];

  useEffect(() => {
    if (userRecord && userRecord.id) {
      const hasGroupAdmin =
        userRecord &&
        userRecord?.currentGroups &&
        userRecord.currentGroups.some((obj) => obj.a === 1);
      setIsAccountGroupAdmin(Boolean(hasGroupAdmin));
      setIsAccountSuperAdmin(Boolean(userRecord?.pvAdministrator === 1));
      const userGroupInfo = userRecord.currentGroups || [];
      if (userGroupInfo.length <= 1) setShowDeleteBtn(true); // *: Only show delete button when a user is in one group
      const formData: IUserFormData = {
        id: userRecord.id,
        pvPersonGivenName: userRecord.pvPersonGivenName || '',
        pvPersonSurName: userRecord.pvPersonSurName || '',
        username: userRecord.username || '',
        email: userRecord.email || '',
        password: '',
        confirmPassword: '',
        pvOfficePhone: userRecord.pvOfficePhone || '',
        pvMobilePhone: userRecord.pvMobilePhone || '',
        pvOrganizationUnitName: userRecord.pvOrganizationUnitName || '',
        subscribeToEmail: userRecord.subscribeToEmail || false,
        pvAdministrator: userRecord.pvAdministrator === 1 ? 1 : undefined,
        pvTrainingAccount: userRecord.pvTrainingAccount ? 1 : 0,
        pvMultipleUser: userRecord.pvMultipleUser || 0,
        pvActiveGroupAdmin: userRecord.pvActiveGroupAdmin || false,
        status: userRecord.status === 'Approved' ? UserStatus.APPROVED : UserStatus.SUSPENDED,
      };
      form.reset(formData);
      const fullName = `${formData.pvPersonGivenName} ${formData.pvPersonSurName}`;
      setUserFullName(fullName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRecord]);

  const navigateBack = () => {
    navigate(-1);
  };

  const onSubmit: SubmitHandler<IUserFormData> = async (data) => {
    try {
      if (data && data.id && userRecord) {
        const payload: IUserUpdatePayload = {
          id: data.id,
          pvPersonGivenName: data.pvPersonGivenName,
          pvPersonSurName: data.pvPersonSurName,
          email: data.email,
          pvMobilePhone: data.pvMobilePhone,
          pvOfficePhone: data.pvOfficePhone,
          pvTrainingAccount: data.pvTrainingAccount,
          pvOrganizationUnitName: data.pvOrganizationUnitName,
          subscribeToEmail: data.subscribeToEmail ? 1 : 0,
          status: data.status,
          username: data.username,
          password: data.password,
          pvAdministrator:
            data.pvAdministrator === undefined
              ? userRecord.pvAdministrator === 1
                ? 1
                : 0
              : data.pvAdministrator,
          pvMultipleUser: data.pvMultipleUser,
          pvAccountID: currentUserAccount?.id || 0,
        };

        if (payload?.password && payload?.password.trim() !== '') {
          console.log('Password is not empty');
          const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
          if (!pattern.test(payload.password)) {
            form.setError('password', {
              message: 'Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number!',
            });
            return;
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete payload.password;
        }
        setIsProcessing(true);
        await dispatch(updateUser(payload))
          .unwrap()
          .then(async () => {
            await refreshUserInfo(payload.id);
          })
          .catch((e) => {
            console.log('error message >>>', e);
          })
          .finally(() => {
            setIsProcessing(false);
            navigate(-1);
          });
      }
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  async function refreshUserInfo(userId: number) {
    await dispatch(fetchSelectedUser(Number(userId)))
      .unwrap()
      .then((resp) => {
        if (resp && resp?.account2position) {
          const dataPermissions: IPermissionList[] = generateDataPermissions(
            resp,
            userPermission,
            userActiveGroupID,
            groupLists,
            currentUserGroupInfo,
            loggedInUserGroupInfo
          );
          if (dataPermissions.length) {
            dispatch(updateUserPermission(dataPermissions));
          }
        }
      })
      .catch((err) => {
        console.log('err >>>', err);
        throw err;
      });
  }

  const handleDeleteUser = async () => {
    const activeGroup =
      (currentUserGroupInfo && currentUserGroupInfo.length && currentUserGroupInfo[0].i) || 0;
    const currentPositions =
      userRecord?.account2position &&
      userRecord?.account2position.length &&
      userRecord?.account2position[0];
    if (userRecord && userRecord.id && activeGroup && currentPositions) {
      // *: Delete user with group and position
      console.log('TRIGGER DELETE USER WITH GROUP AND POSITION');
      setProcessingDelete(true);
      const payload: IRevokeGroupPayload = {
        userId: userRecord?.id,
        groupId: activeGroup,
        positions: currentPositions.positions?.map((item) => item.i) || [],
      };

      dispatch(deleteUserToGroup(payload))
        .unwrap()
        .then(async () => {
          await dispatch(deleteUserEntry(userRecord?.id || 0))
            .unwrap()
            .catch((err) => console.log('err >>>', err));
        })
        .finally(() => {
          setProcessingDelete(false);
          return navigate('/user');
        });
    }

    if (userRecord && userRecord.id && !activeGroup && !currentPositions) {
      // *: Delete user without group and positions
      console.log('TRIGGER DELETE USER ONLY');
      setProcessingDelete(true);
      const userGroupInfo = userRecord.currentGroups || [];
      if (userGroupInfo.length === 0) {
        await dispatch(deleteUserEntry(userRecord?.id || 0))
          .unwrap()
          .catch((err) => {
            console.log('err >>>', err);
          })
          .finally(() => {
            setProcessingDelete(false);
            return navigate('/user');
          });
      }
    }

    if (userRecord && userRecord.id && activeGroup && !currentPositions) {
      // *: Delete user with group but without positions
      console.log('TRIGGER DELETE USER WITH GROUP BUT WITHOUT POSITIONS');
      setProcessingDelete(true);
      const payload: IRevokeGroupPayload = {
        userId: userRecord?.id,
        groupId: activeGroup,
        positions: [],
      };

      dispatch(deleteUserToGroup(payload))
        .unwrap()
        .then(async () => {
          await dispatch(deleteUserEntry(userRecord?.id || 0))
            .unwrap()
            .catch((err) => console.log('err >>>', err));
        })
        .finally(() => {
          setProcessingDelete(false);
          return navigate('/user');
        });
    }
  };

  const combineProps: UserEditProps = {
    accessPermission: userPermission,
    userFullName,
    isProcessing: isFetching || isProcessing,
    form,
    showPassword,
    showDeleteBtn,
    processingDelete,
    isAccountSuperAdmin,
    isAccountGroupAdmin,
    navigateBack,
    onSubmit,
    handleDeleteUser,
    toggleShowPassword: () => setShowPassword(!showPassword),
  };

  return <UserEditView {...combineProps} />;
};

export default UserEdit;
