import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { changeMenu } from 'store/slices/menu/menuSlice';
import { MainLayout } from 'components/layout';
import { DrawerMenu } from 'components/primitives';
import { IconButton, Box } from '@mui/material';
import { PalmettoReactTable } from '@zawarski/palmetto-direflow-react-table';
import { PaperFabButton } from '@zawarski/palmetto-ui-components';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import { UsersTableColumns } from 'common/tables';
import { UserListsProps } from './UserLists.props';
import { IUserTableList } from 'interfaces/usertablelist.interface';
import useStyles from './UserLists.styles';
import { HandleDownloadExcelFile } from 'common/utils';

const UserListsView: React.FC<UserListsProps> = ({
  onRowSelect,
  userActiveGroupID,
  isSuperAdmin,
  ...props
}) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const defaultFilters = useMemo(() => {
    if (!isSuperAdmin) {
      return [`activeGroup=${userActiveGroupID}`];
    } else {
      return null;
    }
  }, [userActiveGroupID, isSuperAdmin]);

  const handleMenu = () => {
    dispatch(changeMenu({ BtnAction: true }));
  };

  const mobileCardContent = (data: IUserTableList) => {
    return data && data.row ? (
      <Box sx={classes.mobileContainer} onClick={() => props.onMobileRowSelect(data)}>
        <Box sx={classes.wrapper}>
          {/*-- 1st row --*/}
          <Box sx={classes.titleRow}>
            <Box sx={classes.title}>{data.row.name}</Box>
          </Box>
          {/*-- 2nd row --*/}
          <Box sx={classes.subRow}>
            <Box sx={classes.subTitle}>{data.row.groups}</Box>
          </Box>
          {/*-- 3rd row --*/}
          <Box sx={classes.subRow}>
            <Box sx={classes.subTitle}>{data.row.administrator}</Box>
          </Box>
        </Box>
      </Box>
    ) : null;
  };

  const nodeEnv = process.env.REACT_APP_NODE_ENV;
  const apiProd = 'https://klqxulghgd.execute-api.us-east-1.amazonaws.com/prod/api';
  const API_BASE_URL = nodeEnv === undefined ? apiProd : process.env.REACT_APP_SLS_LOOPBACK_API;
  const dataURL = `${API_BASE_URL}/users`;
  const manageColumnsURL = `${dataURL}/manage-columns`;

  const handleReport = async () => {
    const ColumnData = await props.fetchColumnsData();
    const params: string[] = [];
    let dataURL = `${API_BASE_URL}/reports/users`;
    const groupName = props.groupLists.find(
      (group) => group.pvGroupID === userActiveGroupID
    )?.pvGroupName;
    if (!isSuperAdmin) {
      params.push(`activeGroup=${userActiveGroupID}`);
    }
    if (groupName) params.push(`GroupName=${groupName}`);
    if (ColumnData && Object.prototype.hasOwnProperty.call(ColumnData, 'search')) {
      const searchData = ColumnData?.search || [];
      searchData.forEach((item) => {
        if (item.enabled && item.value) {
          params.push(`filters[search]=${item.value}`);
        }
      });
    }
    if (ColumnData && Object.prototype.hasOwnProperty.call(ColumnData, 'sort')) {
      const sortData = ColumnData?.sort || [];
      sortData.forEach((item) => {
        if (item.field && item.sort) {
          params.push(`orders[${item.field}]=${item.sort}`);
        }
      });
    }
    if (params.length > 0) {
      dataURL += `?${params.join('&')}`;
    }

    HandleDownloadExcelFile('users-lists', dataURL, props.accessToken).then(() => {
      console.log('DONE GENERATING REPORT');
    });
  };

  return (
    <div
      className={'layout vertical full-height'}
      style={{ display: location.pathname === '/user' ? 'block' : 'none' }}>
      <MainLayout toolbar={<DrawerMenu />}>
        <div className='layout vertical full-height'>
          <Box sx={{ display: 'block', width: '100%', height: '98vh' }}>
            <PalmettoReactTable
              auth={{
                accessToken: props.accessToken,
              }}
              title={<span style={{ fontWeight: 500 }}>Users</span>}
              headerIcon={
                <IconButton onClick={handleMenu}>
                  <MenuIcon sx={{ color: 'white' }} />
                </IconButton>
              }
              columns={UsersTableColumns}
              dataUrl={dataURL}
              defaultFilters={defaultFilters}
              manageColumnsUrl={manageColumnsURL}
              // filterUrl={dataURL}
              hideFilter={true}
              loading={true}
              idField='id'
              limit={35}
              onRowClick={onRowSelect}
              extraHeaders={<></>}
              rowHeight={48}
              noHelp={true}
              components={{
                MobileRow: mobileCardContent,
              }}
              MenuItems={[
                {
                  title: 'Users Report',
                  onClick: handleReport,
                },
              ]}
            />
            <PaperFabButton
              hidden={false}
              onClick={props.handleNewUser}
              style={{ bottom: '80px !important' }}>
              <AddIcon className='icon' />
            </PaperFabButton>
          </Box>
        </div>
      </MainLayout>
    </div>
  );
};

export default UserListsView;
