import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, ref, string } from 'yup';
import { ForgotStatusStep as StatusStep } from 'common/enum';

export interface IResetPassword {
  password: string;
  confirmPassword: string;
  steps: StatusStep;
}

function useResetPasswordForm() {
  const passwordSchema = useMemo(
    () =>
      string()
        .required('This field is required!')
        .matches(
          RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\\-=\\[\\]{}|;:\'",.<>/?])(?=.{8,})'
          ),
          {
            message:
              'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Character!',
            excludeEmptyString: true,
          }
        )
        .max(50, { message: 'Password cannot exceed 50 characters', excludeEmptyString: true }),
    []
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        password: passwordSchema,
        confirmPassword: string().oneOf([ref('password')], 'Password must match'),
        steps: number(),
      }),
    [passwordSchema]
  );

  return useForm<IResetPassword>({
    defaultValues: {
      password: '',
      confirmPassword: '',
      steps: StatusStep.step1,
    },
    resolver: yupResolver(validationSchema),
  });
}

export default useResetPasswordForm;
