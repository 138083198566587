function FormErrorValidation(error: unknown): string {
  if (!error) return '';
  if (typeof error === 'string') return error;
  if (typeof error === 'object' && Object.prototype.hasOwnProperty.call(error, 'message')) {
    // Type assertion to tell TypeScript that error has a message property
    const message = (error as { message: unknown }).message;
    if (typeof message === 'string') return message;
    if (
      typeof message === 'object' &&
      message !== null &&
      Object.prototype.hasOwnProperty.call(message, 'message')
    ) {
      return (message as { message: string }).message;
    }
  }
  return ''; // Add a default return
}

export default FormErrorValidation;
