import { IEndpoint } from 'interfaces/IEndPoint';
import AuthProtect from 'common/enum/AuthProtect';

export const validateResetToken = (data: { accessToken: string; userId: number }): IEndpoint => {
  const { accessToken, userId } = data;
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `/resetpassword/?access_token=${accessToken}&userId=${userId}`,
  };
};

export const resetPassword = (data: {
  userId: number;
  password: string;
  accessToken: string;
}): IEndpoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: '/resetpassword?access_token=' + data.accessToken,
    data,
  };
};

export const failedLoginAttempt = (username: string): IEndpoint => ({
  authorization: AuthProtect.NOT_REQUIRED,
  method: 'PUT',
  url: `/user/failed-login/${username}`,
});

export const saveLoginAttempt = (username: string): IEndpoint => ({
  authorization: AuthProtect.NOT_REQUIRED,
  method: 'PUT',
  url: `/user/save-login-attempt/${username}`,
});

export const fetchLoginAttemptInfo = (username: string): IEndpoint => ({
  authorization: AuthProtect.NOT_REQUIRED,
  method: 'GET',
  url: `/user/login-attempt/${username}`,
});

export const unlockFailedLogin = (id: number): IEndpoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'PUT',
  url: `/user/unlock-failed-login/${id}`,
});
