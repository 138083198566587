import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import UsersListsPage from 'pages/UserLists';

const UserOutlet = () => {
  const location = useLocation();
  return (
    <>
      <UsersListsPage />
      <Outlet key={location.key} />
    </>
  );
};
export default UserOutlet;
