import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, boolean, number, ref } from 'yup';
import { Nullable } from 'common/utils';
import { UserStatus } from 'common/enum';

export interface IUserFormData {
  id?: number;
  pvPersonGivenName: string;
  pvPersonSurName: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  pvOfficePhone: Nullable<string>;
  pvMobilePhone: Nullable<string>;
  pvOrganizationUnitName: string;
  subscribeToEmail: boolean;
  pvAdministrator: number | undefined;
  pvTrainingAccount: number;
  pvMultipleUser: number;
  pvActiveGroupAdmin: boolean;
  status: UserStatus;
}

export const defaultValues: IUserFormData = {
  pvPersonGivenName: '',
  pvPersonSurName: '',
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  pvOfficePhone: '',
  pvMobilePhone: '',
  pvOrganizationUnitName: '',
  subscribeToEmail: false,
  pvAdministrator: undefined,
  pvTrainingAccount: 0,
  pvMultipleUser: 0,
  pvActiveGroupAdmin: false,
  status: UserStatus.APPROVED,
};

function useUserForm() {
  const passwordSchema = useMemo(
    () =>
      string()
        .matches(
          RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\\-=\\[\\]{}|;:\'",.<>/?])(?=.{8,})'
          ),
          {
            message:
              'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Character!',
            excludeEmptyString: true,
          }
        )
        .max(50, { message: 'Password cannot exceed 50 characters', excludeEmptyString: true }),
    []
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        pvPersonGivenName: string().required('This field is required!').max(255),
        pvPersonSurName: string().required('This field is required!').max(255),
        username: string().required('This field is required!').max(255),
        email: string().email('Not a valid email!').required('This field is required!').max(255),
        password: passwordSchema,
        confirmPassword: string().oneOf([ref('password')], 'Password must match'),
        pvOfficePhone: string().nullable().max(255),
        pvMobilePhone: string().nullable().max(255),
        pvOrganizationUnitName: string().required('This field is required!').max(255),
        subscribeToEmail: boolean().required('This field is required!'),
        pvAdministrator: number().nullable(),
        pvTrainingAccount: number().required('This field is required!'),
        pvMultipleUser: number().required('This field is required!'),
        pvActiveGroupAdmin: boolean(),
        status: string().required('This field is required!').max(255),
      }),
    [passwordSchema]
  );

  return useForm<IUserFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useUserForm;
