import { ProgramCodes } from '../enum';

function isAppCodeUseIdToken(appCode: string): boolean {
  return (
    appCode === ProgramCodes.LEMPG ||
    appCode === ProgramCodes.SUPPTL ||
    appCode === ProgramCodes.MITG ||
    appCode === ProgramCodes.REP ||
    appCode === ProgramCodes.VEOC ||
    appCode === ProgramCodes.DMA ||
    appCode === ProgramCodes.DMAM ||
    appCode === ProgramCodes.CDOWN ||
    appCode === ProgramCodes.SERCLEPC ||
    appCode === ProgramCodes.EGRESS ||
    appCode === ProgramCodes.PTRACK ||
    appCode === ProgramCodes.DECON ||
    appCode === ProgramCodes.DB30 ||
    appCode === ProgramCodes.MP30
  );
}

export default isAppCodeUseIdToken;
